/* eslint-disable lingui/no-unlocalized-strings */
export const navigate = (href: string) => {
    const root = !href.toLowerCase().includes('http') ? 'FakeFolder/' : '';
    window.parent.postMessage(
        { type: 'innerNavigation', location: `${root}${href}` },
        window.maintain_baseUrl || '',
    );
};

export const windowParentLocation = {
    /*get href() {
        return this._name;
      },*/
    set href(newLocation: string) {
        navigate(newLocation);
    },
};

export const windowOpen = (...args: Parameters<typeof window.open>) => {
    const url = args[0];
    const root =
        !url?.toString().toLowerCase().includes('http') && window.maintain_location
            ? window.maintain_location.origin + window.maintain_location.pathname + 'FakeFolder/'
            : '';
    args[0] = `${root}${url}`;
    return window.open(...args);
};

export const formNavigate = (href: string, fields: Record<string, unknown>) => {
    const root = !href.toLowerCase().includes('http') ? 'FakeFolder/' : '';
    window.parent.postMessage(
        {
            type: 'formNavigation',
            location: `${root}${href}`,
            fields,
        },
        window.maintain_baseUrl || '',
    );
};

export const getBaseHref = () => {
    return window.maintain_location
        ? window.maintain_location.origin + window.maintain_location.pathname + 'FakeFolder/'
        : '';
};
