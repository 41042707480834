import axios, { AxiosResponse, AxiosError, ResponseType } from 'axios';
import qs from 'qs';

type MaintainResponseType = ResponseType | 'download';
// eslint-disable-next-line lingui/no-unlocalized-strings
const MissingBuildError = 'Missing from Build';
enum MimeTypes {
    formUrlEncoded = 'application/x-www-form-urlencoded',
}
export interface ILoader {
    // Name is a reference so that we can see where this was called, like the old flash movie name.
    name: string;

    // `method` is the request method to be used when making the request
    // default is get
    method?: 'get' | 'post';

    // `baseURL` will be prepended to `url` unless `url` is absolute.
    baseURL?: string;

    // `URL` is the server URL that will be used for the request
    URL: string;

    // `params` are the URL parameters to be sent with the request Must be a plain object or a URLSearchParams object
    params?: { [x: string]: any };

    // `data` is a json formatted data to be sent in a post.
    data?: object | FormData;

    // `responseType` indicates the type of data that the server will respond with
    // default is json
    responseType?: MaintainResponseType;

    // The request content type
    // Default is 'application/x-www-form-urlencoded'
    contentType?: string;

    // `timeout` specifies the number of milliseconds before the request times out. If the request takes longer than `timeout`, the request will be aborted.
    // default is 60 seconds. set to 0 for no timeout
    timeout?: number;

    // set it false when you don't want to add to your requests the following props: lStart, lNum
    additionalProps?: boolean;
}

export interface IDefaultResponseBase {
    asp_id: number;
    asp_status: number;
    maxRows_data?: number;
    error?: boolean;
    message?: IDefaultMessage[];
    asp_error?: string;
    errorIdentifier?: string;
}

export type IDefaultResponse<T = undefined, Extra = undefined> = T extends undefined // | null
    ? Extra extends undefined
        ? IDefaultResponseBase
        : IDefaultResponseBase & Extra
    : Extra extends undefined
      ? IDefaultResponseBase & { data?: T[] }
      : IDefaultResponseBase & { data?: T[] } & Extra;

interface IDefaultMessage {
    heading: string;
    body: string;
}

export interface IAMTransactionEvent {
    description: string;
    action: 'reload' | 'logout';
    userName?: string;
    dimension?: string;
    errorIdentifier?: string;
}

const isMaintainResponse = (variableToCheck: unknown): variableToCheck is IDefaultResponseBase =>
    (variableToCheck as IDefaultResponseBase).asp_id !== undefined;

const isAxiosResponse = (variableToCheck: unknown): variableToCheck is AxiosResponse =>
    (variableToCheck as AxiosResponse).config !== undefined;

const isDefaultResponse = (variableToCheck: unknown): variableToCheck is IDefaultResponse =>
    (variableToCheck as IDefaultResponse | undefined)?.asp_id !== undefined;

const retryAjax = <T extends AxiosResponse | AxiosError>(value: T): T | Promise<T> => {
    if (axios.isAxiosError(value)) {
        if (
            // If some sort of retryable error, then retry it.
            [
                AxiosError.ERR_NETWORK, // Network-related issue.
                AxiosError.ERR_BAD_RESPONSE, // Response cannot be parsed properly or is in an unexpected format.
                AxiosError.ECONNABORTED, // Request timed out due to exceeding timeout specified in axios configuration.
                AxiosError.ETIMEDOUT, // Request timed out due to exceeding default axios timelimit.
            ].includes(value.code ?? '')
        ) {
            const config = value.config;
            if (!config?.params) {
                return value;
            }
            const params = config.params as { flash_an: number; flash_id: number };

            if (++params.flash_an < 4) {
                return new Promise((resolve) => {
                    // This is a back-off calculation so that we retry but wait longer between retries.
                    // considering returning this to the server as a header/ parameter
                    const newTimeout =
                        (7500 + Math.random() * 7500) * Math.pow(2.1, params.flash_an - 2);
                    setTimeout(() => resolve(axios(config)), newTimeout);
                });
            } else {
                return value;
            }
        }
    } else if (isAxiosResponse(value)) {
        const config = value.config;
        if (!config) {
            return Promise.reject(value);
        }
        if (isDefaultResponse(value.data)) {
            value.data.asp_id = Number(value.data.asp_id);
            value.data.asp_status = Number(value.data.asp_status);
            const data = value.data;
            /*
            2 Flash finished processing data sent from ASP.
            1 OK, transaction completed.
            0 Executing (internal for both Flash / ASP)
            -1 Error, return to login page (Sent with Error description)
            -2 Error, return to order list (Home page if on order list) and refresh it (with description)
            */
            // Negative response codes are dealt with in the response received section below, maybe should move it up here.
            if (data.asp_status < 0) {
                return value;
            }
            const params = config.params as { flash_an: number; flash_id: number };
            if (data.asp_status !== 1 || params.flash_id !== data.asp_id) {
                if (++params.flash_an < 4) {
                    return new Promise((resolve) =>
                        setTimeout(() => resolve(axios(config)), 75 * params.flash_an),
                    );
                } else {
                    return Promise.reject(new Error('Transaction error'));
                }
            }
        }
        return value;
    }
    return value;
};

axios.interceptors.response.use(retryAjax, retryAjax);

axios.interceptors.request.use((config) => {
    if (
        ((config.baseURL || '') + (config.url || '') + '?' + qs.stringify(config.params)).length >
        1800
    ) {
        config.method = 'post';
        config.data = (config.data as object) || {};
        Object.keys(config.params).forEach((key: string) => {
            if (key.indexOf('flash') === -1 && key !== 'lStart' && key !== 'lNum') {
                config.data[key] = config.params[key];
                delete config.params[key];
            }
        });
    }

    if (
        config.data &&
        config.headers &&
        ((Object.prototype.hasOwnProperty.call(config.headers, 'contentType') &&
            config.headers.contentType === MimeTypes.formUrlEncoded) ||
            (Object.prototype.hasOwnProperty.call(config.headers, 'Content-Type') &&
                config.headers['Content-Type'] === MimeTypes.formUrlEncoded)) &&
        config.method === 'post'
    ) {
        config.data = qs.stringify(config.data);
    }
    return config;
});

const raiseAMEvent = (eventPrams: IAMTransactionEvent) => {
    const AMTransactionEvent = new CustomEvent<IAMTransactionEvent>('AM_TransactionEvent', {
        detail: eventPrams,
    });
    dispatchEvent(AMTransactionEvent);
};

export const Get = <
    DataRowType = undefined,
    ExtraBaseFields = undefined,
    BaseReturnTypeDontProvide extends
        | IDefaultResponse<DataRowType, ExtraBaseFields>
        | string = DataRowType extends undefined
        ? ExtraBaseFields extends undefined
            ? any
            : IDefaultResponse<unknown, ExtraBaseFields> //this should be unknown but is DataRowType
        : IDefaultResponse<DataRowType, ExtraBaseFields>,
>(
    props: ILoader,
): Promise<BaseReturnTypeDontProvide | undefined> => {
    if (props.URL.indexOf('?') !== -1) {
        throw new Error('URL contains parameters, use params object instead.');
    } else {
        if (!props.params) {
            props.params = {};
        } else {
            for (const paramName in props.params) {
                if (Object.prototype.hasOwnProperty.call(props.params, paramName)) {
                    const value = props.params[paramName];
                    if (typeof value === 'boolean') {
                        props.params[paramName] = Math.abs(Number(value)); // convert Boolean to number.
                    }

                    if (value instanceof Date && value.getSQL120) {
                        props.params[paramName] = value.getSQL120(); // convert Boolean to number.
                    }
                }
            }
        }
        if (props.params.csv === 1) {
            props.responseType = 'text';
        }
        props.params.flash_id = Math.round(Math.random() * 100000000);
        props.params.flash_an = 1;
        props.params.flash_swf = 'ReTAG-' + props.name;
        props.params.flash_uid = window.flash_uid;
        if (props.additionalProps !== false) {
            props.params.lStart = props.params.lStart || 0;
            props.params.lNum = props.params.lNum || -1;
        }
        props.responseType = props.responseType || 'json';
        if (props.responseType === 'json') {
            props.params.json = props.params.json || 'data';
        }

        if (props.baseURL === undefined && window.maintain_location) {
            props.baseURL = window.maintain_location.origin + window.maintain_location.pathname;
        }
    }
    async function load() {
        let data: BaseReturnTypeDontProvide | undefined;

        const loadData = new Promise((resolve) => {
            if (
                props.responseType === 'download' ||
                (props.params && props.params.csv && props.params.csv === 1)
            ) {
                let params = '';
                if (props.params) {
                    for (const prop in props.params) {
                        if (
                            Object.prototype.hasOwnProperty.call(props.params, prop) &&
                            props.params[prop] !== undefined
                        ) {
                            params += prop + '=' + encodeURIComponent(props.params[prop]) + '&';
                        }
                    }
                }
                const csvID = Math.round(Math.random() * 100000000).toString();
                DownloadFile(
                    `../${props.URL}?${params}${
                        props.params && props.params.csv
                            ? (params.slice(-1) === '&' ? '' : '&') + 'csvID=' + csvID
                            : ''
                    }`,
                );
                if (props.params && props.params.csv) {
                    const timeout = props.timeout || 60000;
                    const startDateTime = new Date().getTime();
                    const csvInt = setInterval(() => {
                        const currentDateTime = new Date().getTime();
                        if (
                            getCookieValue('csvID') === csvID ||
                            (currentDateTime - startDateTime) / 1000 >= timeout
                        ) {
                            clearInterval(csvInt);
                            resolve(true);
                        }
                    }, 150);
                } else {
                    resolve(true);
                }
            } else {
                let V1Key = '';
                if (window.localStorage) {
                    V1Key = localStorage.getItem('V1Key') ?? '';
                }
                resolve(
                    axios
                        .request<IDefaultResponse<unknown, ExtraBaseFields>>({
                            //^ This type should be `Base` not `IDefaultResponse<unknown, Extra>` but we have too many existing files with wrong types.
                            method: props.method || 'get',
                            baseURL: props.baseURL || '', //add static base url in here later Window.prototype.maintain_baseUrl.
                            url: (!props.baseURL ? '../' : '') + props.URL, // Removed process.env.REACT_APP_ROOTPATH as we always want to load from the real API now that we're running from 6577
                            params: props.params,
                            data: props.data,
                            responseType: props.responseType || 'json',
                            timeout: props.timeout || 60000,
                            headers: {
                                'Content-Type': props.contentType || MimeTypes.formUrlEncoded,
                                V1Key,
                                MaintainVersion:
                                    process.env.REACT_APP_BUILDNUMBER || MissingBuildError,
                            },
                            withCredentials: true,
                        })
                        .catch((error: AxiosError) => {
                            let errorMessage;
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                // eslint-disable-next-line no-console
                                console.error(error.response.data);
                                // eslint-disable-next-line no-console
                                console.error(error.response.status);
                                // eslint-disable-next-line no-console
                                console.error(error.response.headers);
                                errorMessage = `Bad response`;
                            } else if (error.request) {
                                // The request was made but no response was received
                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                // http.ClientRequest in node.js
                                // eslint-disable-next-line no-console
                                console.error(error.request);
                                errorMessage = `No response`;
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                // eslint-disable-next-line no-console
                                console.error('Error', error);
                                errorMessage = `Axios Error`;
                            }
                            raiseAMEvent({
                                description: 'An Error occurred accessing the server',
                                action: 'reload',
                            });
                            const stackErr = new Error();
                            axios({
                                method: 'post',
                                baseURL:
                                    window.maintain_baseUrl !== undefined
                                        ? window.maintain_baseUrl + '/dummy/folder/'
                                        : '',
                                url: '../../Common/ASP/logFlashError.asp',
                                data: {
                                    nv_dimension: '',
                                    nv_mc: props.name,
                                    nv_url: props.URL,
                                    nv_error: errorMessage,
                                    nv_errorDetails: JSON.stringify(error),
                                    nv_stackTrace: JSON.stringify(stackErr?.stack),
                                    nv_from: 'AxiosError',
                                },
                                headers: {
                                    'Content-Type': MimeTypes.formUrlEncoded,
                                    V1Key,
                                    MaintainVersion:
                                        process.env.REACT_APP_BUILDNUMBER || MissingBuildError,
                                },
                            });
                        })
                        .then((response) => {
                            if (response && response.status && response.status === 200) {
                                if (
                                    response.data &&
                                    isMaintainResponse(response.data) &&
                                    response.data.asp_status < 0 &&
                                    response.data.asp_error
                                ) {
                                    raiseAMEvent({
                                        description: response.data.asp_error,
                                        action:
                                            response.data.asp_status === -1 ? 'logout' : 'reload',
                                        errorIdentifier: response.data?.errorIdentifier,
                                    });
                                } else if (
                                    response.data &&
                                    isMaintainResponse(response.data) &&
                                    typeof response.data.maxRows_data !== undefined &&
                                    response.data.maxRows_data === 0
                                ) {
                                    response.data.data = [];
                                }
                                if (
                                    props.responseType === 'download' ||
                                    (props.params && props.params.csv && props.params.csv === 1)
                                ) {
                                    const filename = response.headers['content-disposition']
                                        .split('filename=')[1]
                                        .split('"')
                                        .join('');
                                    const link = document.createElement('a');

                                    if (
                                        props.params &&
                                        props.params.csv &&
                                        props.params.csv === 1 &&
                                        !isMaintainResponse(response.data)
                                    ) {
                                        const blob = new Blob(
                                            [
                                                new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
                                                response.data,
                                            ],
                                            { type: 'text/plain;charset=utf-8' },
                                        );

                                        // if it's CSV we have to put the unicode BOM markers in otherwise the files are mangled.
                                        const url = window.URL.createObjectURL(blob);
                                        link.href = url;
                                    } else if (!isMaintainResponse(response.data)) {
                                        // If it's a download skip to the making the blob and setting the href.
                                        const url = window.URL.createObjectURL(
                                            new Blob([response.data]),
                                        );
                                        link.href = url;
                                    }
                                    if (filename) {
                                        link.setAttribute('download', filename); // Set filename from headers returned.
                                    }
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                } else {
                                    data = response.data as BaseReturnTypeDontProvide;
                                }
                            } else {
                                raiseAMEvent({
                                    description: 'An Error occurred accessing the server',
                                    action: 'reload',
                                });
                                const stackErr = new Error();
                                axios({
                                    method: 'post',
                                    baseURL:
                                        window.maintain_baseUrl !== undefined
                                            ? window.maintain_baseUrl + '/dummy/folder/'
                                            : '',
                                    url: '../../Common/ASP/logFlashError.asp',
                                    data: {
                                        nv_dimension: '',
                                        nv_mc: props.name,
                                        nv_url: props.URL,
                                        nv_error: 'Bad server response',
                                        nv_errorDetails: JSON.stringify(response),
                                        nv_stackTrace: JSON.stringify(stackErr?.stack),
                                        nv_from: 'Backend',
                                    },
                                    headers: {
                                        'Content-Type': MimeTypes.formUrlEncoded,
                                        V1Key,
                                        MaintainVersion:
                                            process.env.REACT_APP_BUILDNUMBER || MissingBuildError,
                                    },
                                });
                                //data = 'error';
                            }
                        }),
                );
            }
        });

        await loadData;
        return data;
    }

    return load();
};

interface IFrame extends HTMLElement {
    src?: string;
}

export const DownloadFile = (url: string) => {
    const iFrameID = 'download';
    let iFrame: IFrame | null = document.getElementById(iFrameID);
    if (iFrame === null) {
        iFrame = document.createElement('iframe');
        iFrame.id = iFrameID;
        iFrame.style.display = 'none';
        document.body.appendChild(iFrame);
    }
    if (iFrame && iFrame.src !== undefined) {
        iFrame.src = url;
    }
};

const getCookieValue = (cookieName: string) => {
    let cookieValue = undefined;
    document.cookie.split('; ').forEach((element) => {
        const cookie = element.split('=');
        if (cookie[0] === cookieName) {
            if (cookie[1] && cookie[1] !== '') {
                cookieValue = cookie[1];
            }
        }
    });
    return cookieValue;
};
