import { IGeneralAuth } from '../../Scenes/Authentication/AuthContext';
import { Get, IDefaultResponse } from './Loader';

interface IGetUserAuthInfoResponse extends IDefaultResponse {
    data: IGeneralAuth[];
    featureFlags: string[];
}

const getUserAuthInfo = () => {
    async function load() {
        const data = Get({
            name: 'getUserAuthInfo',
            URL: '../Common/asp/GetUserAuthInfo.asp',
            params: {
                flaver: '10000000000000000000000000,0,0',
            },
        });
        await data;

        return data.then((response: IGetUserAuthInfoResponse | undefined) => {
            return response;
        });
    }
    return load();
};

export default getUserAuthInfo;
